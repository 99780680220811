<template>
  <router-view />
</template>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  height: 100vh;
  font-family: "Poppins", sans-serif;

  text-align: center;
  color: #2c3e50;
  display: flex;
  align-items: center;

  position: relative;
}
</style>
