
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomeView",
  components: {},

  data() {
    return {
      text_to_scramble: "",
      scramble_degree: 5,

      iterations: 0,

      msg_show: false,

      rows: 10,
      dots: 10,

      max_purple: 20,
      max_white: 20,

      purple: 0,
      white: 0,

      white_locations: [
        { x: 4, y: 6 },
        { x: 2, y: 9 },
        { x: 8, y: 1 },
        { x: 9, y: 3 },
      ],

      purple_locations: [
        { x: 2, y: 6 },
        { x: 7, y: 2 },
        { x: 2, y: 5 },
        { x: 5, y: 7 },
      ],
    };
  },

  methods: {
    color(index: any, row_index: any): string {
      let color = "black";

      if (
        this.purple_locations.some(
          (location) => location.x === index && location.y === row_index
        )
      ) {
        color = "#caa4f8";
      }

      if (
        this.white_locations.some(
          (location) => location.x === index && location.y === row_index
        )
      ) {
        color = "white";
      }

      return `background: ${color}`;
    },
    scramble() {
      //insert zero width space at random positions
      if (this.text_to_scramble.length === 0) return;

      let scrambled_text = "";

      for (let i = 0; i < this.text_to_scramble.length; i++) {
        scrambled_text += this.text_to_scramble[i];

        if (Math.random() * 100 < this.scramble_degree) {
          scrambled_text += "\u200B";
        }
      }

      this.text_to_scramble = scrambled_text;
      this.iterations++;
      this.randomizeLocations();
    },

    clear() {
      this.text_to_scramble = "";
      this.iterations = 0;
    },

    randomizeLocations() {
      console.log("randomizeLocations");

      this.white_locations = [];
      this.purple_locations = [];

      for (let i = 0; i < this.max_white; i++) {
        this.white_locations.push({
          x: Math.floor(Math.random() * this.dots),
          y: Math.floor(Math.random() * this.rows),
        });
      }

      for (let i = 0; i < this.max_purple; i++) {
        this.purple_locations.push({
          x: Math.floor(Math.random() * this.dots),
          y: Math.floor(Math.random() * this.rows),
        });
      }
    },

    copy() {
      this.msg_show = true;
      navigator.clipboard.writeText(this.text_to_scramble);

      setTimeout(() => {
        this.msg_show = false;
      }, 2000);
    },
  },

  mounted() {
    /* setInterval(() => {
      this.randomizeLocations();
    }, 2000); */
  },

  computed: {
    charCount(): number {
      return this.text_to_scramble.length;
    },

    wordCount(): number {
      return this.text_to_scramble.split(" ").length;
    },
  },
});
