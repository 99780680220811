import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAyHa5bsN_cXoX3g_CqaRdeWjtrjuKppkg",
    authDomain: "aiblend-17db6.firebaseapp.com",
    projectId: "aiblend-17db6",
    storageBucket: "aiblend-17db6.appspot.com",
    messagingSenderId: "476324936759",
    appId: "1:476324936759:web:9e27df82fa7cd6570213b2",
    measurementId: "G-RM47K6ZEED"
  };
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


createApp(App).use(store).use(router).mount('#app')

export { app,analytics};
